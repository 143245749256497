import { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import SearchIcon from '@mui/icons-material/Search';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Home from './pages/Home'
import Bookmarks from './pages/Bookmarks'
import Confirm from './pages/Confirm'
import TopSearchbar from './components/TopSearchbar'
import logo from './images/logo-blue.png';
import Dropdown from './components/Dropdown';
import insta_logo from './images/instagram-logo.png'
import tiktok_logo from './images/tiktok_logo.png'
import Privacy from './pages/Privacy'
import './App.css';
import { Helmet } from 'react-helmet';
import { useDetectAdBlock } from 'adblock-detect-react';
import GamesHome from './pages/Games/Home'
import Games from './pages/Games/Games';
import Apps from './pages/Games/Apps';
import Settings from './pages/Games/Settings';
import Play from './pages/Games/Play'
import Newsletter from './components/Newsletter';
import Submit from './pages/Submit';
import NotFound from './pages/NotFound';
import UnSub from './pages/UnSub';
import Terms from './pages/Terms';
import { Appeal } from './components/Appeal';
let ogSites = Object.values(await import('./backend/websiteSample2.json')).slice(-1)[0];
let sites = ogSites;

const options = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: false,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.2,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: true,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ['name', 'summary']
}

function App() {

  const addOnScroll = 30;
  const menuRef = useRef(null);
  const [ searching, setSearching ] = useState(false)
  const [ query, setQuery ] = useState('~all')
  const [ filteredSites, setFilteredSites ] = useState(sites);
  const [ menu, setMenu ] = useState(false);
  const [ selectedTool, setSelectedTool ] = useState(' All Tools');
  const [ filter, setFilter ] = useState('All')
  const [ num, setNum ] = useState(addOnScroll)
  const [ loading, setLoading ] = useState(false)
  const [ small, setSmall ] = useState(false)
  const [ adBlocker, setAdBlocker ] = useState(false);

  const adBlockDetected = useDetectAdBlock();

  const [ openPopup, setOpenPopup ] = useState('');

  const emailSubject = "Website Submission";
  const emailBody = "Please find the details of my website submission below:\n\n[Website Name] \n\n[Website URL] \n\n[About the Website] ";

  // Construct the mailto link
  const mailtoLink = `mailto:contact@mangoai.co?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

  async function delayedImport() {
    await new Promise(resolve => setTimeout(resolve, 200));
    try {
      ogSites = Object.values(await import('./backend/websites2.json')).slice(-1)[0];
      sessionStorage.setItem('total', parseInt(ogSites.length * 1.25))
    } catch (error) {
      console.error('Error loading JSON:', error);
    }
  }
  useEffect(() => {
    delayedImport();
  }, [])

  const communityLinks = [
    { text: 'Tiktok', logo: tiktok_logo, url: 'https://www.tiktok.com/@mangoaico' },
    { text: 'Instagram', logo: insta_logo, url: 'https://www.instagram.com/mangoai.co' },
  ];

  const updateSites = () => {
    let filtered = []

    if (filter === 'Free') {
      sites = ogSites.filter(site => site.pricing_plan === 'Free');
    } else if (filter === 'Mid') {
      sites = ogSites.filter(site => site.pricing_plan === 'Freemium');
    } else if (filter === 'Paid') {
      sites = ogSites.filter(site => site.pricing_plan === 'Paid');
    } else {
      sites = ogSites;
    }

    // Filters boxes
    if (query.includes('1~')) {
      filtered = sites.filter(site => site.tag.toLowerCase().includes(query.toLowerCase().replace(/[^a-z]/gi, '')));
    // News
    } else {
      // const found = sites.filter(site => site.name.toLowerCase().replace(/\s/g,'').includes(query.toLowerCase().replace(/\s/g,'')));
      // if (found) filtered = found
      // else filtered = sites.filter(site => site.summary.toLowerCase().replace(/\s/g,'').includes(query.toLowerCase().replace(/\s/g,'')));
      const fuse = new Fuse(sites, options)
      filtered = fuse.search(query).map(res => res.item)
      }
    // All tools
    if (query.toLowerCase().replace(/\s/g,'').includes('~all')) {
      filtered = sites;
    }

    if (filtered.length <= num) setLoading(false)

    const existingData = localStorage.getItem('bookmarkData');
    const bookmarkData = existingData ? JSON.parse(existingData) : {};

    for (const filteredObj of sites) {
      if (bookmarkData.hasOwnProperty(filteredObj.name)) {
        filteredObj.marked = true;
      }
    }

    // Insert Ads into websites array
    if (!filtered.some(item => item.name === "adbygoogle")) {
      for (let i = 4; i < filtered.length; i += 5) {
        filtered.splice(i, 0, {
          "name": "adbygoogle",
          "tag": "",
          "summary": Math.random().toString(),
          "image": "",
          "website_link": "",
          "id": "",
          "href": "",
          "marked": false
        });
      }
    }

    if (filtered.length < 5 && filtered.length > 0) {
      filtered.splice(1, 0, {
        "name": "adbygoogle",
        "tag": "",
        "summary": Math.random().toString(),
        "image": "",
        "website_link": "",
        "id": "",
        "href": "",
        "marked": false
      });
    }
    return filtered
  }

  useEffect(() => {
   if (filter === 'Free') {
      sites = ogSites.filter(site => site.pricing_plan === 'Free');
    } else if (filter === 'Freemium') {
      sites = ogSites.filter(site => site.pricing_plan === 'Freemium');
    } else if (filter === 'Paid') {
      sites = ogSites.filter(site => site.pricing_plan === 'Paid');
    } else {
      sites = ogSites;
    }
  }, [filter])

  useEffect(() => {
    if (window.scrollY > 215) {
      window.scrollTo({ top: 0, behavior: 'smooth'})
    }
    if (!query.includes("~")) setSelectedTool(' All Tools')
    if (query === "") setQuery('1~all')
    setNum(addOnScroll)
  }, [query])

  useEffect(() => {
    const filtered = updateSites();
    sessionStorage.setItem('total', filtered.length);
    setFilteredSites(filtered.slice(0, addOnScroll));
  }, [query, filter])

  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > window.document.body.offsetHeight - 1400 && sessionStorage.getItem('total') > num - addOnScroll) {
        document.removeEventListener('scroll', handleScroll);
        setLoading(true);

        const filtered = updateSites();
        setFilteredSites(filtered.slice(0, num));
        setNum(num + addOnScroll);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 765) setSmall(true)
      else setSmall(false)
    };
    handleResize();
    window.history.scrollRestoration = 'manual'

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!menuRef.current.contains(event.target)) {
        setMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setAdBlocker(adBlockDetected);
    adBlockDetected && (document.body.style.overflow = 'hidden')
  }, [adBlockDetected]);

  const handleMenuToggle = () => {
    setMenu(!menu);
  };

  const handleKeyDown = (e) => {
    if (e.key.toLowerCase() === localStorage.getItem('panicKey').toLowerCase()) {
      if (localStorage.getItem('panicUrl') && localStorage.getItem('panicUrl').includes('https://')) window.top.location.href = localStorage.getItem('panicUrl');
      else if (localStorage.getItem('panicUrl') && !localStorage.getItem('panicUrl').includes('https://')) window.top.location.href = 'https://' + localStorage.getItem('panicUrl');
      else window.top.location.href = 'https://google.com'
    }
  }

  return (
    <Router>
      <div className="app" >

         <div className={openPopup && 'popup-background'} >
            <div className={"newsletterPopup " + openPopup} >
               <Newsletter setOpenPopup={setOpenPopup} />
            </div>
         </div>

         <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-3FPC038ZBH"></script>
          <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-3FPC038ZBH');
              `}
          </script>
         </Helmet>
        <div className="navigation">
          <div className="nav_container" >
            <div className="logo" >
              <Link to='/' >
                <img src={logo} onClick={() => window.location.href = '/'} alt="mangoai logo" />
              </Link>
            </div>
            <div className="topics" style={small ? {display: 'none'} : {display: 'flex'}} >
              {/* <Link to='/' >
                <Dropdown
                  title="AI Tools"
                  setQuery={setQuery}
                  emoji=""
                  setSelectedTool={setSelectedTool}
                  />
              </Link> */}
              <Link to='/bookmarks' >
                <p>Bookmarks</p>
              </Link>

              {/* <a href={mailtoLink} >
                <p>Submit</p>
              </a> */}

              <a href='mailto:contact@mangoai.co' >
                <p>Contact Us</p>
              </a>

            </div>
            <div className="search_container" >
              {/* <Link to='/' style={small ? {display: 'none'} : {display: 'flex'}} >
                <SearchIcon onClick={() => {setSearching(true); setSelectedTool(null)}} />
              </Link> */}
              <Link onClick={() => setOpenPopup('open-popup')} className='subscribe_btn' >
                <p>Join Newsletter</p>
              </Link>
            </div>
            <div className="smallnavbar_container" style={!small ? {display: 'none'} : {display: 'flex'}} ref={menuRef} >
              <MenuOutlinedIcon onClick={handleMenuToggle} />
              <div className={`menu_open ${menu ? 'active' : ''}`} >
                <div className="top_container" >
                  <h1 style={{width: '90%', padding: '25px 0px 25px 25px', fontSize: '25px', margin: '0'}} >Mango AI</h1>
                  <CloseOutlinedIcon
                    style={{padding: '25px', marginLeft: 'auto'}}
                    onClick={() => setMenu(false)} />
                </div>
                <hr style={{width: '85%', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.1)', margin: '0 20px'}} />
                <ul>
                  <li onClick={() => window.location = '/bookmarks'} >Bookmarks</li>
                  <li onClick={() => window.location = 'mailto:contact@mangoai.co'} >Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="search_modal" >
          {searching && (
            <TopSearchbar setSearching={setSearching} setQuery={setQuery} />
          )}
        </div> */}
        {adBlocker && (
          <div className="popup-background">
            <div className="popup open-popup">
              <h1>Ad Blocker Detected</h1>
              <p>Please disable it to support us!</p>
              <button onClick={() => window.location.reload()} >I disabled my ad blocker</button>
            </div>
          </div>
        )}

      </div>
      <Routes>
        {/* Actual Pages */}
        <Route exact path='/' element={<Home query={query} setQuery={setQuery} filteredSites={filteredSites} selectedTool={selectedTool} setSelectedTool={setSelectedTool} small={small} setFilter={setFilter} loading={loading} setLoading={setLoading} />} />
        <Route path= '/privacy' element={<Privacy/>}/>
        <Route path='/terms' element={<Terms/>} />
        <Route path='/bookmarks' element={<Bookmarks sites={sites} />} />
        <Route path='/confirmation' element={<Confirm query={query} setQuery={setQuery} filteredSites={filteredSites} selectedTool={selectedTool} setSelectedTool={setSelectedTool} small={small} setFilter={setFilter} />} />
        <Route path='/unsubscribe' element={<UnSub />} />
        <Route path='/appeal' element={<Appeal />} />
        <Route path='*' element={<NotFound />} />
        {/* <Route path='/submit' element={<Submit />} /> */}
        {/* School Unlocked */}
        <Route path='/schoolunlocked/' element={<GamesHome handleKeyDown={handleKeyDown} />} />
        <Route path='/schoolunlocked/games' element={<Games handleKeyDown={handleKeyDown} />} />
        <Route path='/schoolunlocked/apps' element={<Apps handleKeyDown={handleKeyDown} />} />
        <Route path='/schoolunlocked/settings' element={<Settings handleKeyDown={handleKeyDown} />} />
        <Route path='/schoolunlocked/play' element={<Play handleKeyDown={handleKeyDown} />} />
        <Route path='/instapassword' element={<iframe src='/InstaPassword/index.html' title='Insta Password' style={{width: '100%', height: '85vh', border: 'none'}}/>} />
      </Routes>
    </Router>
  );
}

export default App;
