import React from 'react';
import { HeroSection } from './appeal-components/HeroSection.js';
import { AboutSection } from './appeal-components/AboutSection.js';

export function Appeal() {
  return <div className="flex flex-col min-h-screen bg-white">
      <main className="flex-grow">
        <HeroSection />
        <AboutSection />
      </main>
    </div>;
}