import React from 'react';
import { CameraIcon, BrainIcon, CheckCircleIcon } from 'lucide-react';
import './AboutSection.css'; // Import the CSS file

export function AboutSection() {
  const features = [
   {
      icon: <CameraIcon className="icon" />,
      title: 'Snap & Submit',
      description: 'Just take a photo of your parking ticket and upload it. That’s all it takes to get started.',
    },
    {
      icon: <BrainIcon className="icon" />,
      title: 'Smart AI Handling',
      description: 'Trained on thousands of real appeals, our AI takes care of everything from writing to submitting.',
    },
    {
      icon: <CheckCircleIcon className="icon" />,
      title: 'Proven Results',
      description: 'Users have seen an 80% success rate—no stress, no hassle, just real results.',
    },
  ];

  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="header">
          <h2 className="about-title">How AppealGPT Fights Your Tickets</h2>
          <p className="about-description">
            We use AI to analyze your ticket and craft winning appeals in minutes.
          </p>
        </div>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
        <div className="cta-section">
          <div className="cta-content">
            <h3 className="cta-title">Ready to Fight Back?</h3>
            <p className="cta-description">
              Join thousands of eager drivers ready to save money with AppealGPT.
            </p>
            <a href="#early-access" className="cta-button">
              Get Early Access
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
