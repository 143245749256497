import React, { useState } from 'react';
import './HeroSection.css'; // Import the CSS file
import { db } from '../../backend/firebase';
import { addDoc, collection } from 'firebase/firestore';

export function HeroSection() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      await addDoc(collection(db, "appeal"), {email: email})
      setSubmitted(true);
      setEmail('');
      setTimeout(() => {
        setSubmitted(false);
      }, 3000);
    }
  };

  return (
    <section className="hero-section" id="early-access">
      <div className="container">
        <div className="content">
          <h1 className="title">
            Appeal parking tickets with <span className="highlight">AI</span>
          </h1>
          {/* <p className="description">
            Save time and money with AI-powered parking ticket appeals
          </p> */}
          <form onSubmit={handleSubmit} className="form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email for early access"
              className="input"
              required
            />
            <button type="submit" className="submit-button">
              {submitted ? (
                <>
                  <span style={{fontWeight: 600}}>Success!</span>
                </>
              ) : (
                <>
                  <span className="nowrap">Join Waitlist</span>
                </>
              )}
            </button>
          </form>
          {/* <p className="subtext">
            ✨ Be the first to get access when we launch. No spam, ever.
          </p> */}
          {/* <div id="bullet-points">
            <div className="stat-item">
              <div className="stat-circle"><span>$0</span></div>
              <p>No upfront costs</p>
            </div>
            <div className="stat-item">
              <div className="stat-circle"><span>1m</span></div>
              <p>Average submission time</p>
            </div>
            <div className="stat-item">
              <div className="stat-circle"><span>100%</span></div>
              <p>Moneyback Guarantee</p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
